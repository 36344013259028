import Vue from 'vue'
import Router from 'vue-router'
import { JWTService } from '@/shared/services/jwt'

// Containers
import PublicBaseLayout from '@/containers/PublicBaseLayout'
import PrivateBaseLayout from '@/containers/PrivateBaseLayout'

// Public Views
import Authentication from '@/views/public/auth/Authentication.vue'
import AuthUserPassword from '@/views/public/auth/UserPassword.vue'
import LostPassword from '@/views/public/password/lost/LostPassword.vue'
import ChangePassword from '@/views/public/password/change/ChangePassword.vue'

// Privates Views
import StartPage from '@/views/private/StartPage'
import AdmUser from '@/views/private/access/user/user.vue'
import AdmGroups from '@/views/private/access/groups/groups.vue'
import AdmPermission from '@/views/private/access/permission/permission.vue'
import MyAccount from '@/views/private/myaccount/profile/profile.vue'
import Company from '@/views/private/settings/company/company.vue'
import Branch from '@/views/private/settings/branch/branch.vue'
import Customers from '@/views/private/support/customers/customers.vue'
import TemplateBusinessType from '@/views/private/settings/templates/businesstype/businesstype.vue'
import TemplateCategory from '@/views/private/settings/templates/category/category.vue'
import TemplateProduct from '@/views/private/settings/templates/product/product.vue'
import TemplateContactType from '@/views/private/settings/templates/contacttype/contacttype.vue'
import TemplateLeadOrigin from '@/views/private/settings/templates/leadorigin/leadorigin.vue'
import TemplateReasonLoss from '@/views/private/settings/templates/reasonloss/reasonloss.vue'
import Campaign from '@/views/private/comunications/mailing/campaign/campaign.vue'
import CampaignList from '@/views/private/comunications/mailing/emaillist/emaillist.vue'
import CampaignTemplate from '@/views/private/comunications/mailing/template/template.vue'

Vue.use(Router)

/**
 * @function assertLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
const assertLoggedIn = (to, from, next) => {
  // console.log(JWTService.GetToken())
  if (!JWTService.GetToken()) {
    next({ name: 'SignIn' })
    return
  }
  next()
}
/**
 * @function assertNotLoggedIn
 * @description Middleware para ser executado antes da troca de rota para garantir
 * que os usuários não estão autenticados para ver esta rota.
 * @param {Object} to A rota de onde o usuário está vindo.
 * @param {Object} from A rota que o usuário deseja acessar.
 * @param {Object} next A rota que será renderizada.
 */
const assertNotLoggedIn = (to, from, next) => {
  if (JWTService.GetToken()) {
    next({ name: 'StartPage' })
    return
  }
  next()
}

/**
 * @function route
 * @description Cria um objeto de rota.
 * @param {string} path O caminho da rota.
 * @param {string} name O nome da rota.
 * @param {Object} component O componente a ser renderizado na rota.
 * @param {Array} children As children routes desta rota.
 * @returns {Object} Um objeto de rota do vue-router.
 */
const route = (path, name, component, children, beforeEnter, meta) => ({
  path,
  name,
  component,
  children,
  beforeEnter,
  meta
})

/**
 * @function children
 * @description Transforma um objetos com rotas em array.
 * @param {...} routes As rotas para serem retornadas em forma de array.
 * @returns {Array} O array com as rotas.
 */
const children = (...routes) => routes

/**
 * @property adminRoutes
 * @description As rotas privadas da aplicação.
 * @type {Object}
 */
const adminRoutes = {
  path: '/pagina-inicial',
  redirect: '/pagina-inicial',
  component: PrivateBaseLayout,
  beforeEnter: assertLoggedIn,
  children: children(
    route('/pagina-inicial', 'StartPage', StartPage),
    route('/acessos/usuarios', 'Usuarios', AdmUser),
    route('/acessos/grupos', 'Grupos', AdmGroups),
    route('/acessos/permissoes', 'Permissoes', AdmPermission),
    route('/minha-conta', 'MinhaConta', MyAccount),
    route('/configuracoes/empresa', 'Empresa', Company),
    route('/configuracoes/filial', 'Filial', Branch),
    route('/configuracoes/template/segmento', 'TemplateSegmento', TemplateBusinessType), 
    route('/configuracoes/template/categoria', 'TemplateCategoria', TemplateCategory),
    route('/configuracoes/template/produto', 'TemplateProduto', TemplateProduct), 
    route('/configuracoes/template/tipo-de-contato', 'TemplateTipoContato', TemplateContactType), 
    route('/configuracoes/template/origem-do-lead', 'TemplateOrigemLead', TemplateLeadOrigin),
    route('/configuracoes/template/motivo-de-perda', 'TemplateMotivoPerda', TemplateReasonLoss),
    route('/suporte/clientes', 'Clientes', Customers),
    route('/comunicacao/mailing/envio', 'Campaign', Campaign),
    route('/comunicacao/mailing/lista', 'CampaignList', CampaignList),
    route('/comunicacao/mailing/template', 'CampaignTemplate', CampaignTemplate)
  )
}

/**
 * @property publicRoutes
 * @description As rotas públicas da aplicação. (Não requerem autenticação)
 * @type {Object}
 */

const publicRoutes = {
  path: '/',
  redirect: '/login',
  component: PublicBaseLayout,
  children: children(
    route('/loginOld', 'SignInOld', Authentication, null, assertNotLoggedIn),
    route('/login', 'SignIn', AuthUserPassword, null, assertNotLoggedIn),
    route('/redefinir-senha', 'LostPassword', LostPassword, null, assertNotLoggedIn),
    route('/redefinir-senha/token/:token', 'ChangePassword', ChangePassword, null, assertNotLoggedIn)
  )
}

export default new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    publicRoutes,
    adminRoutes,
    {
      path: '*',
      component: PublicBaseLayout
    },
    {
      'method': 'GET',
      'path': '/static/fonts/:file',
      'type': 'passthrough',
      'service': 'static/fonts/:file'
    }
  ]
})
