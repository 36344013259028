import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'

import CustomerSearchForm from './search-form.js'
const CustomerSearchModel = { name: null }

export default {
  name: 'CustomerSearch',
  mixins: [ActionsForm],
  data () {
    return {
      data: CustomerSearchModel,
      form: CustomerSearchForm
    }
  },
  props: {
    parentComponent: {
      type: Object
    }
  },
  mounted () {
    this.onClearFilters()
  },
  methods: {
    onLoad() {
    },
    onClearFilters() {
      Tools.SetFormDefaultValues(this.form)
      this.parentComponent.filters = null
      this.parentComponent.onLoad()
    },
    onValidateFilters() {  
      Tools.SetValueToSave(this.form, this.data)     
      return true
    },
    onFilter() {
      if (this.onValidateFilters()) {
        this.$eventHub.emit('Filter', { filters: this.data })
      }
    },
  },
}
