<template>
  <div class="animated fadeIn">
    <Forms ref="frmTemplate"
           :title="'CADASTRO DE TEMPLATE'"
           :showClearButton="true"
           :showSaveButton="true"
           :showBackButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
           v-on:Back="$emit('Back')"
    >
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtName"><small class="text-danger">*</small> Nome:</label>
          <div class="input-group">
            <b-form-input type="text"
                          id="txtName"
                          name="txtName"
                          :placeholder="'Nome do template'"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtGroup"><small class="text-danger">*</small> Template:</label>
          <div class="input-group">
            <b-form-select v-model="form.emailTemplateID.value" :options="form.emailTemplateID.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-6">
          <label for="txtGroup"><small class="text-danger">*</small>Lista:</label>
          <div class="input-group">
            <b-form-select v-model="form.emailListID.value" :options="form.emailListID.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="col-md-3">
          <label for="txtName"><small class="text-danger">*</small> Data de envio:</label>
          <div>
            <b-form-datepicker v-model="form.dateSend.value" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
          </div>
        </div>
        <div class="col-md-3">
          <label for="txtName"><small class="text-danger">*</small> Situação:</label>
          <div class="input-group">
            <b-form-select v-model="form.situation.value" :options="form.situation.options">
              <template slot="first">
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
                <option :value="0" selected>PENDENTE</option>
                <option :value="1">EM ANDAMENTO</option>
                <option :value="2">CONCLUÍDO</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
    </Forms>
  </div>
</template>
<script>
import CampaignAdd from './campaignadd.js'
export default CampaignAdd
</script>
