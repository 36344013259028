export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  description: {
    value: '',
    maxlength: '1000',
    required: false,
    rows: '30',
    noResize: true
  }
}
