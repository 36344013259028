import ActionsForm from '@/shared/mixins/actionsForm'

import TemplateList from './list/templatelist.vue'
import TemplateAdd from './add/templateadd.vue'

export default {
  name: 'MailingTemplate',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null,
      showNew: false
    }
  },
  components: {
    TemplateList,
    TemplateAdd
  },
  methods: {
    onNew() {
      this.showNew = true
    },
    onBack() {
      this.showNew = false
    },
    onEdit(obj) {
      this.showNew = true
      setTimeout(() => {
        this.$refs.TemplateAdd.onEdit(obj)
      }, 200)
    }
  }
}