import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import Tools from '@/components/tools/Tools.js'

import EmailListRepository from '@/shared/http/repositories/admin/emaillist.js'
import EmailListModel from '@/shared/models/admin/emaillist.js'
import EmailListForms from './emaillistadd-form.js'

import EmailListItems from './itens/emaillistitems.vue'

export default {
  name: 'EmailListAdd',
  mixins: [ActionsForm],
  data () {
    return {
      data: EmailListModel,
      form: EmailListForms
    }
  },
  components: {
    EmailListItems
  },
  methods:{
    onCreate () {
      loading.push()
      EmailListRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      EmailListRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    }
  }
}