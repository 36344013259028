import APIClient from '@/shared/http/clients/api'

const resourceName = 'permission'
const APIAccess = process.env.VUE_APP_MANAGER

const GetAll = (data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}`, data, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)

const PermissionRepository = {
  GetAll,
  Create
}

export default PermissionRepository
