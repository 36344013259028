<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12 pt-3 pb-3 pl-4">
        <h3><i class="fas fa-address-book"></i> Lista</h3>
      </div>
    </div>
    <ListAdd ref="ListAdd" v-on:Back="onBack" v-if="showNew"/>
    <ListGrid ref="ListGrid" v-on:New="onNew" v-on:Edit="onEdit" v-else />
  </div>
</template>
<script>
import Emails from './emaillist.js'
export default Emails
</script>
