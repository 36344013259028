import { toast } from '@/shared/utils/UIHelper'

export default {
  name: 'LostPassword',
  data () {
    return {
      version: process.env.VersionApp,
      email: null
    }
  },
  methods: {
    onResetPassword () {
      if (this.email) {
        toast.success('<strong>Senha redefinida com sucesso!</strong><br><br>As informações foram enviadas para seu email!')
        this.email = null
      } else {
        toast.error('ATENÇÃO! Informe seu email')
      }
    }
  }
}
