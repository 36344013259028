export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  description: {
    value: '',
    maxlength: '120',
    required: false,
    state: null
  },
  mnemonic: {
    value: '',
    maxlength: '70',
    required: false,
    state: null
  }
}