<template>
  <div class="animated fadeIn">
    <Tables
      ref="tableGrid"
      :columns="[
        'Grupo',
        $lang.Common.DataTable.Status,
        $lang.Common.DataTable.ActionDate,
        $lang.Common.DataTable.AccountAction,
        $lang.Common.DataTable.Actions
      ]"
      :showSearch="false"
      v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import ActionsForm from '@/shared/mixins/actionsForm'
export default {
  name: 'CustomerGroup',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null
    }
  },
  
}
</script>