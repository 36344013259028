
export default {
  name: 'ChangePassword',
  data () {
    return {
      version: process.env.VersionApp,
      token: null,
      password: null,
      confirmation: null
    }
  },
  mounted () {
    this.token = this.$route.params.token
  }
}
