var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animated fadeIn"},[_c('Tables',{ref:"tableGrid",attrs:{"columns":[
      'Nome',
      'Usar Plataforma',
      'Qtde. E-mail',
      _vm.$lang.Common.DataTable.Status,
      _vm.$lang.Common.DataTable.ActionDate,
      _vm.$lang.Common.DataTable.AccountAction,
      _vm.$lang.Common.DataTable.Actions
    ],"showSearch":false,"showNew":true},on:{"Load":_vm.onLoad,"New":function($event){return _vm.$emit('New')}}},_vm._l((this.objList),function(obj){return _c('tr',{key:obj.id},[_c('TableColumn',{attrs:{"IsText":true,"status":obj.status,"value":obj.name},on:{"Edit":function($event){return _vm.onEdit(obj)}}}),_c('TableColumn',{attrs:{"IsText":true,"status":obj.status,"value":obj.usingAccount ? 'SIM' : 'NÃO'},on:{"Edit":function($event){return _vm.onEdit(obj)}}}),_c('TableColumn',{attrs:{"IsText":true,"status":obj.status,"value":obj.quantity},on:{"Edit":function($event){return _vm.onEdit(obj)}}}),_c('TableColumn',{attrs:{"IsStatus":true,"status":obj.status},on:{"Edit":function($event){return _vm.onEdit(obj)}}}),_c('TableColumn',{attrs:{"IsDatetime":true,"status":obj.status,"value":obj.dateAction},on:{"Edit":function($event){return _vm.onEdit(obj)}}}),_c('TableColumn',{attrs:{"IsText":true,"status":obj.status,"value":obj.accountUserNameAction},on:{"Edit":function($event){return _vm.onEdit(obj)}}}),_c('TableColumn',{attrs:{"IsActionsButtons":true,"status":obj.status},on:{"Change":function($event){return _vm.onChange(obj)},"Delete":function($event){return _vm.onDelete(obj)}}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }