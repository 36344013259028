import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import TemplateReasonLossRepository from '@/shared/http/repositories/settings/templatereasonloss.js'
import TemplateModel from '@/shared/models/settings/template/templatemodel.js'
import TemplateReasonLossForms from './reasonloss-form'

export default {
  name: 'reasonloss',
  mixins: [ActionsForm],
  data () {
    return {
      data: TemplateModel,
      form: TemplateReasonLossForms
    }
  },
  methods: {
    onLoad () {
      loading.push()
      TemplateReasonLossRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      TemplateReasonLossRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      TemplateReasonLossRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      TemplateReasonLossRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
