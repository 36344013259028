const roles = {
  /** @description MENU ACESSOS */
  MENU_ACCESS: 'MENU_ACCESS',
  /** @description MENU CONFIGURAÇÕES */
  MENU_CONFIGURATION: 'MENU_CONFIGURATION',
  /** @description MENU SUPERVISORIO */
  MENU_SUPERVISORY: 'MENU_SUPERVISORY',

  /** @description ADM - CONTAS (LEITURA) */
  RD_ADM_ACCOUNTS: 'RD_ADM_ACCOUNTS',
  /** @description ADM - GRUPOS (LEITURA) */
  RD_ADM_GROUPS: 'RD_ADM_GROUPS',
  /** @description ADM - PERMISSÕES (LEITURA) */
  RD_ADM_PERMISSIONS: 'RD_ADM_PERMISSIONS',
  /** @description ADM - CONTAS (ESCRITA) */
  RW_ADM_ACCOUNTS: 'RW_ADM_ACCOUNTS',
  /** @description ADM - GRUPOS (ESCRITA) */
  RW_ADM_GROUPS: 'RW_ADM_GROUPS',
  /** @description ADM - PERMISSÕES (ESCRITA) */
  RW_ADM_PERMISSIONS: 'RW_ADM_PERMISSIONS',

}
export default roles
