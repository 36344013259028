import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools.js'
import { loading } from '@/shared/utils/UIHelper'

import TemplateBusinessTypeRepository from '@/shared/http/repositories/settings/templatebusinesstype.js'
import TemplateCategoryRepository from '@/shared/http/repositories/settings/templatecategory.js'
import TemplateProductRepository from '@/shared/http/repositories/settings/templateproduct.js'
import TemplateProductModel from '@/shared/models/settings/template/templateproductmodel.js'
import TemplateProductForms from './product-form'

export default {
  name: 'product',
  mixins: [ActionsForm],
  data () {
    return {
      data: TemplateProductModel,
      form: TemplateProductForms
    }
  },
  watch: {
    'form.businessTypeID.value': function (businessTypeID) {
      if (businessTypeID) {
        if (this.form.templateCategoryID.options.length > 0) {
          this.form.templateCategoryID.options = []
          this.form.templateCategoryID.value = null
        }
        this.onChangeBusinessType(businessTypeID)
      } else {
        this.form.templateCategoryID.options = []
        this.form.templateCategoryID.value = null
      }
    }
  },
  created() {
    this.onLoadAllBusinessType()
  },
  methods: {
    onEdit (obj) {
      loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      this.form.businessTypeID.value = obj.templateCategory.templateBusinessTypeID
      loading.pop()
    },
    onLoad () {
      loading.push()
      TemplateProductRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      TemplateProductRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      TemplateProductRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      TemplateProductRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllBusinessType() {
      loading.push()
      TemplateBusinessTypeRepository.GetAll()
        .then((response) => {
          this.form.businessTypeID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onChangeBusinessType(id) {
      loading.push()
      TemplateCategoryRepository.GetAllByBusinessType(id)
        .then((response) => {
          this.form.templateCategoryID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
