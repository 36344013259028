import Tools from '@/components/tools/Tools.js'
import ActionsForm from '@/shared/mixins/actionsForm'
import { toast, loading } from '@/shared/utils/UIHelper'

import GroupRepository from '@/shared/http/repositories/access/group'
import UserRepository from '@/shared/http/repositories/access/user.js'
import AccountModel from '@/shared/models/access/accountmodel'
import AccountForms from './user-form'

export default {
  name: 'User',
  mixins: [ActionsForm],
  data () {
    return {
      data: AccountModel,
      form: AccountForms
    }
  },
  created () {
    this.onClear()
  },
  mounted () {
    this.onLoadAllGroup()
  },
  computed: {
    FieldSizeTip: function () {
      return this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
    }
  },
  methods: {
    onLoad () {
      loading.push()
      UserRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onEdit (obj) {
      loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      this.form.password.value = null
      loading.pop()
    },
    onCreate () {
      loading.push()
      if (this.data.password) {
        this.data.password = this.data.password.toString()
      }
      UserRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onUpdate () {
      loading.push()
      if (this.data.password) {
        this.data.password = this.data.password.toString()
      } else { this.data.password = '' }

      UserRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onDelete (obj) {
      loading.push()
      UserRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllGroup () {
      loading.push()
      GroupRepository.GetAll()
        .then((response) => {
          this.form.groupID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCheckEmail () {
      var email = this.form.email.value
      loading.push()
      UserRepository.GetByEmail(email)
        .then((response) => {
          if (response) {
            if (response.data.data) {
              var obj = response.data.data
              if (obj) {
                toast.warning(this.$lang.Accounts.AlertEmailExisted)
              }
            }
          }
          loading.pop()
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
