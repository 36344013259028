<template>
  <div class="animated fadeIn">
    <Tables
      ref="tableGrid"
      :columns="[
        'Filial',
        'Empresa',
        $lang.Common.DataTable.Status,
        $lang.Common.DataTable.ActionDate,
        $lang.Common.DataTable.AccountAction,
        $lang.Common.DataTable.Actions
      ]"
      :showSearch="false"
      v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.company ? obj.company.name : ''" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import BranchRepository from '@/shared/http/repositories/settings/branch'

export default {
  name: 'CustomerBranch',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null
    }
  },
  props: {
    costumer: {
      type: Object,
      required: true
    }
  },
  methods: {
    onLoad () {
      /*
      if(this.costumer) {
        this.onLoadAllBranch(this.costumer.id)
      }
      */
    },
    onLoadAllBranch (tenantID) {
      loading.push()
      BranchRepository.GetAllPaging(tenantID, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then(this.onResultGrid)
        .catch(this.onException)
    },
  }
}
</script>