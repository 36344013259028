<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12 pt-3 pb-3 pl-4">
        <h3><i class="fas fa-envelope-open-text"></i> Template</h3>
      </div>
    </div>
    <TemplateAdd ref="TemplateAdd" v-on:Back="onBack" v-if="showNew"/>
    <TemplateList ref="TemplateList" v-on:New="onNew" v-on:Edit="onEdit" v-else/>
  </div>
</template>
<script>
import MailingTemplate from './template.js'
export default MailingTemplate
</script>
