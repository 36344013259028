import APIClient from '@/shared/http/clients/api'

const resourceName = 'user'
const APIAccess = process.env.VUE_APP_MANAGER

const Get = (accountID, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/${accountID}`, config)
const GetLogged = (config = {}) => APIClient.get(`${APIAccess}/${resourceName}/logged`, config)
const GetAll = (data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}`, data, config)
const GetAllAnalist = (data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/analist`, data, config)
const GetAllExpert = (data = {}, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/expert`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIAccess}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/${id}`, config)
const GetByEmail = (email, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/email/${email}`, config)
const GetByUsername = (username, config = {}) => APIClient.get(`${APIAccess}/${resourceName}/username/${username}`, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIAccess}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIAccess}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIAccess}/${resourceName}/${id}`, config)

const UserRepository = {
  Get,
  GetLogged,
  GetAll,
  GetAllPaging,
  GetAllAnalist,
  GetAllExpert,
  GetById,
  GetByEmail,
  GetByUsername,
  Create,
  Update,
  Delete
}

export default UserRepository
