<template>
  <b-modal
    id="modal-new"
    ref="modal-new"
    size="md"
    centered
    no-close-on-backdrop
  >
    <template #modal-header>
      <h5>Novo Cliente</h5>
    </template>
    <div class="form-group row">
      <div class="col-md-12 pb-2">
        <label for="txtOrganization"><small class="text-danger">*</small> {{$lang.Customers.New.LabelOrganization}}:</label>
        <div class="input-group">
          <b-form-input type="text"
                        :placeholder="$lang.Customers.New.PlaceholderEnterOrganization"
                        v-model="form.organization.value"
                        :size="form.organization.maxlength"
                        :required="form.organization.required"
                        :state="form.organization.state"
          ></b-form-input>
        </div>
      </div>
      <div class="col-md-12 pb-2">
        <label for="txtName"><small class="text-danger">*</small> {{$lang.Customers.New.LabelName}}:</label>
        <div class="input-group">
          <b-form-input type="text"
                        :placeholder="$lang.Accounts.PlaceholderEnterName"
                        v-model="form.name.value"
                        :size="form.name.maxlength"
                        :required="form.name.required"
                        :state="form.name.state"
          ></b-form-input>
        </div>
      </div>
      <div class="col-md-12 pb-2">
        <label for="txtEmail"><small class="text-danger">*</small> {{$lang.Customers.New.LabelEmail}}:</label>
        <div class="input-group">
          <b-form-input type="email"
                        :placeholder="$lang.Accounts.PlaceholderEnterEmail"
                        v-model="form.email.value"
                        :size="form.email.maxlength"
                        :required="form.email.required"
                        :state="form.email.state"
                        autocomplete="false"
                        @blur.native="onCheckEmail"
          ></b-form-input>
        </div>
      </div>
      <div class="col-md-12 pb-2">
        <label for="txtPassword">
          <small class="text-danger">*</small> {{$lang.Customers.New.LabelPassword}}:
        </label>
        <div class="input-group">
          <b-form-input
            type="password"
            :placeholder="$lang.Customers.New.PlaceholderEnterPassword"
            v-model="form.password.value"
            :size="form.password.maxlength"
            :required="form.password.required"
            :state="form.password.state"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="txtPassword"><small class="text-danger">*</small> {{$lang.Customers.New.LabelConfirmPassword}}:</label>
        <div class="input-group">
          <b-form-input
            type="password"
            :placeholder="$lang.Customers.New.PlaceholderEnterConfirmPassword"
            v-model="form.confirmPassword.value"
            :size="form.confirmPassword.maxlength"
            :required="form.confirmPassword.required"
            :state="form.confirmPassword.state"
          />
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="row col-12">
        <div class="text-left">
          <button type="button" @click="onGetPassword" class="btn btn-danger btn-sm mr-5" v-b-tooltip.hover :title="'Clique para gerar a senha'"><i class="fas fa-key"></i> Gerar senha</button>&nbsp;
          <button type="button" @click="onCreate" class="btn btn-third btn-sm ml-5" v-b-tooltip.hover :title="$lang.Common.Tooltip.ButtonSave"><i class="fa fa-check-circle"></i> {{$lang.Common.Buttons.Save}}</button>&nbsp;
          <button type="button" @click="close" class="btn btn-default btn-sm text-secundary" v-b-tooltip.hover :title="$lang.Common.Tooltip.ButtonClose"><i class="fa fa-minus-circle"></i> {{$lang.Common.Buttons.Close}}</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ActionsForm from '@/shared/mixins/actionsForm'
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'

import NewCostumerModel from '@/shared/models/access/newcostumermodel'
import NewCostumerForm from './newcostumer-form'

import CostumerRepository from '@/shared/http/repositories/access/costumer'

export default {
  name: 'ModalNewCostumer',
  mixins: [ActionsForm],
  data () {
    return {
      data: NewCostumerModel,
      form: NewCostumerForm
    }
  },
  methods: {
    show () {
      this.$bvModal.show('modal-new')
    },
    close () {
      this.$bvModal.hide('modal-new')
      this.onClear()
    },
    onCreate () {
      if (Tools.ValidateForms(this.form, this.data, self, toast)) {
        if (this.data) {
          loading.push()
          if (this.data.password) {
            this.data.password = this.data.password.toString()
          }
          CostumerRepository.Create(this.data)
            .then((response) => {
              const { code } = response.data

              this.onResult(response)
              
              if (code === 200) this.$emit('Created')
            })
            .catch((err) => {
              this.onException(err)
            })
        } else {
          toast.error(this.$lang.Common.NoRecordInformed)
        }
      }
    },
    onGetPassword() {
      this.form.password.value = ''
      this.form.confirmPassword.value = ''

      let password = Tools.GeneratePassword(13)
      this.form.password.value = password
      this.form.confirmPassword.value = password
    }
  }
}
</script>
