export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  email: {
    value: null,
    maxlength: '70',
    required: true,
    state: null
  },
  groupID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  cellPhone: {
    value: null,
    maxlength: '20',
    required: true,
    state: null
  },
  password: {
    value: '',
    maxlength: '30',
    required: false,
    state: null
  },
  confirmation: {
    value: '',
    maxlength: '30',
    required: false,
    state: null
  }
}
