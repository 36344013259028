import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import TemplateBusinessTypeRepository from '@/shared/http/repositories/settings/templatebusinesstype.js'
import TemplateCategoryRepository from '@/shared/http/repositories/settings/templatecategory.js'
import TemplateCategoryModel from '@/shared/models/settings/template/templatecategorymodel.js'
import TemplateCategoryForms from './category-form'

export default {
  name: 'category',
  mixins: [ActionsForm],
  data () {
    return {
      data: TemplateCategoryModel,
      form: TemplateCategoryForms
    }
  },
  created() {
    this.onLoadAllBusinessType()
  },
  methods: {
    onLoad () {
      loading.push()
      TemplateCategoryRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      TemplateCategoryRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      TemplateCategoryRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      TemplateCategoryRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllBusinessType() {
      loading.push()
      TemplateBusinessTypeRepository.GetAll()
        .then((response) => {
          this.form.templateBusinessTypeID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
