<template>
  <ul class="nav">
    <li class="nav-item" >
      <div class="nav-link" @click="setAction" exact><i class="ico icon-arrow-left"></i>  {{$lang.Menu.Common.MenuDefault}}</div>
    </li>
    <li class="nav-title">
      {{$lang.Menu.Section.Settings}}
    </li>
    <router-link v-if="hasRole(this.roles.MENU_ACCESS)" tag="li" class="nav-item nav-dropdown" :to="{ path: '/acessos' }" disabled>
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-user-shield"></i> {{$lang.Menu.Items.Access.Access}}</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item" v-if="hasRole(this.roles.RD_ADM_ACCOUNTS) || hasRole(this.roles.RW_ADM_ACCOUNTS)">
          <router-link :to="{ name: 'Usuarios' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Access.Users}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_ADM_GROUPS) || hasRole(this.roles.RW_ADM_GROUPS)">
          <router-link :to="{ name: 'Grupos' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Access.Groups}}</router-link>
        </li>
        <li class="nav-item" v-if="hasRole(this.roles.RD_ADM_PERMISSIONS) || hasRole(this.roles.RW_ADM_PERMISSIONS)">
          <router-link :to="{ name: 'Permissoes' }" class="nav-link" exact><i class="fa fa-caret-right" /> {{$lang.Menu.Items.Access.Permissions}}</router-link>
        </li>
      </ul>
    </router-link>

    <router-link tag="li" class="nav-item nav-dropdown" :to="{ path: '/template' }" disabled>
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-gift"></i> Templates Produtos</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item">
          <router-link :to="{ name: 'TemplateSegmento' }" class="nav-link" exact><i class="fa fa-caret-right" /> Segmento</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'TemplateCategoria' }" class="nav-link" exact><i class="fa fa-caret-right" /> Categoria</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'TemplateProduto' }" class="nav-link" exact><i class="fa fa-caret-right" /> Produto/Serviços</router-link>
        </li>
      </ul>
    </router-link>

    <router-link tag="li" class="nav-item nav-dropdown" :to="{ path: '/template' }" disabled>
      <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-address-card"></i> Templates CRM</div>
      <ul class="nav-dropdown-items">
        <li class="nav-item">
          <router-link :to="{ name: 'TemplateTipoContato' }" class="nav-link" exact><i class="fa fa-caret-right" /> Forma de contato</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'TemplateOrigemLead' }" class="nav-link" exact><i class="fa fa-caret-right" /> Origem do Lead</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'TemplateMotivoPerda' }" class="nav-link" exact><i class="fa fa-caret-right" /> Motivo de perda</router-link>
        </li>
      </ul>
    </router-link>

    <li class="divider" />
  </ul>
</template>
<script>
import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'
export default {
  name: 'SidebarSettings',
  mixins: [ PermissionMixin ],
  data () {
    return {
      roles: Roles
    }
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    setAction () {
      this.$emit('Action')
    },
    hasRole (role) {
      return true
      // var result = false
      // if (this.permissions) {
      //   for (var i in this.permissions) {
      //     if (this.permissions[i].role === role) {
      //       result = true
      //     }
      //   }
      // }
      // return result
    }
  }
}
</script>
<style lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
