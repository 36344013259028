import ActionsForm from '@/shared/mixins/actionsForm'
import { toast, loading } from '@/shared/utils/UIHelper'

import UserRepository from '@/shared/http/repositories/access/user.js'
import AccountModel from '@/shared/models/access/accountmodel'
import AccountForms from './profile-form'

export default {
  name: 'profile',
  mixins: [ActionsForm],
  data () {
    return {
      data: AccountModel,
      form: AccountForms
    }
  },
  mounted () {
  },
  methods: {
    onUpdate () {
      loading.push()
      UserRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCheckEmail () {
      var email = this.form.email.value
      loading.push()
      UserRepository.GetByEmail(email)
        .then((response) => {
          if (response) {
            if (response.data.data) {
              var obj = response.data.data
              if (obj) {
                toast.warning(this.$lang.Accounts.AlertEmailExisted)
              }
            }
          }
          loading.pop()
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
