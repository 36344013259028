import APIClient from '@/shared/http/clients/api'

const resourceName = 'account'
const APIManager = process.env.VUE_APP_MANAGER

const Get = (accountID, config = {}) => APIClient.get(`${APIManager}/${resourceName}/${accountID}`, config)
const GetAll = (data = {}, config = {}) => APIClient.get(`${APIManager}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIManager}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIManager}/${resourceName}/${id}`, config)
const GetByEmail = (email, config = {}) => APIClient.get(`${APIManager}/${resourceName}/email/${email}`, config)
const GetByUsername = (username, config = {}) => APIClient.get(`${APIManager}/${resourceName}/username/${username}`, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIManager}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIManager}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIManager}/${resourceName}/${id}`, config)

const AccountRepository = {
  Get,
  GetAll,
  GetAllPaging,
  GetById,
  GetByEmail,
  GetByUsername,
  Create,
  Update,
  Delete
}

export default AccountRepository
