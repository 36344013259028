<template>
  <div class="app flex-row align-items-center bg-img">
    <div class="login-block-logo">
      <img
        src="/static/img/logotipo/SaluDigital02.png"
        style="width: 240px;"
      >
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-4">
              <div class="card-block">
                <h1>Redefinir Senha</h1>
                <p class="text-muted">
                  Digite as informações abaixo para redifinir sua senha!
                </p>
                <div class="input-group mb-4">
                  <span class="input-group-addon"><i class="icon-envelope" /></span>
                  <b-form-input
                    type="email"
                    id="email"
                    name="email"
                    :placeholder="'Digite seu Email'"
                    v-model="email"
                  />
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <router-link
                      :to="{ name: 'SignIn' }"
                      class="btn btn-link px-0"
                    >
                      <i class="fa fa-history" /> Voltar
                    </router-link>
                  </div>
                  <div class="col-6 text-right">
                    <button
                      type="button"
                      class="btn btn-third px-4"
                      @click="onResetPassword"
                    >
                      <i class="fa fa-check-circle" /> Redefinir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center pt-3 login-version">
        <a
          href="http://jeugenio.com.br"
          target="_blank"
          style="color: #fff;"
        >Powered by JEUGENIO IT &nbsp;-&nbsp; {{ $lang.Login.LabelVersion }} {{ version }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import LostPassword from './LostPassword.js'

export default LostPassword

</script>
