<template>
  <div id="app">
    <transition name="fade">
      <loading v-show="isLoading" />
    </transition>
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import CommonMixin from '@/store/module/common/mixin'
import Loading from '@/components/UI/Loading'

export default {
  name: 'App',
  mixins: [CommonMixin],
  watch: {
    $route () {
      this.RemoveMinimizedSidebar()
      this.RemoveMaximizedItens()
    }
  },
  components: {
    [Loading.name]: Loading
  },
  methods: {
    RemoveMinimizedSidebar() {
      if (!document.body.classList.contains('sidebar-minimized')) {
        document.body.classList.add('sidebar-minimized')
      }

      document.body.classList.remove('sidebar-mobile-show')
      window.scrollTo(0, 0)
    },
    RemoveMaximizedItens () {
      let elements = document.getElementsByClassName('nav-dropdown open')

      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.toggle('open')
      }
    },
    RemoveAsidebar() {
      document.body.classList.remove('aside-menu-fixed')
      if (!document.body.classList.contains('aside-menu-hidden')) {
        document.body.classList.add('aside-menu-hidden')
      }
    }
  }
}
</script>

<style lang="scss">
  /* purgecss start ignore */
  @import "../node_modules/@hokify/vuejs-datepicker/dist/vuejs-datepicker.css";
  @import "../scss/style.scss";
  /* purgecss end ignore */
</style>
