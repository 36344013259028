import APIClient from '@/shared/http/clients/api'

const resourceName = 'templateproduct'
const APIManager = process.env.VUE_APP_MANAGER

const GetAll = (data = {}, config = {}) => APIClient.get(`${APIManager}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIManager}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIManager}/${resourceName}/${id}`, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIManager}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIManager}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIManager}/${resourceName}/${id}`, config)

const TemplateProductRepository = {
  GetAll,
  GetAllPaging,
  GetById,
  Create,
  Update,
  Delete
}

export default TemplateProductRepository
