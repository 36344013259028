<template>
  <navbar>
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      &#9776;
    </button>
    <router-link
      :to="{ name: 'StartPage' }"
      class="navbar-brand"
    >
      <small :class="display" />
    </router-link>
    <ul class="nav navbar-nav d-md-down-none">
      <li class="nav-item">
        <a
          class="nav-link navbar-toggler sidebar-toggler"
          href="#"
          @click="sidebarMinimize"
        >&#9776;</a>
      </li>
      <li class="nav-item px-3">
        <a
          class="nav-link"
          href="javascript:void(0)"
          @click="toggleFullScreen"
        ><i
          class="fas fa-expand-arrows-alt"
          v-b-tooltip.hover
          :title="$lang.Common.Buttons.Fullscreen"
        /></a>
      </li>
    </ul>
    <ul class="nav navbar-nav ml-auto">
      <li class="divider-vertical d-md-down-none" />
      <li class="nav-item d-sm-down-none">
        <a class="nav-link" href="#"><i class="fas fa-flag" /></a>
      </li>
      <li class="nav-item d-sm-down-none">
        <a class="nav-link" href="javascript:void(0);"><i class="icon-bell" /><span class="badge badge-pill badge-default">0</span></a>
      </li>
      <li class="divider-vertical d-md-down-none" />
      <li class="nav-item d-sm-down-none">
        <a class="nav-link" href="javascript:void(0);" @click="asideToggle"><i class="fas fa-comments" /></a>
      </li>
      <li class="nav-item d-md-down-none">
        <a class="nav-link" href="javascript:void(0);"><i class="fas fa-envelope" /></a>
      </li>
      <li class="divider-vertical d-md-down-none mr-3" />

      <b-dropdown
        variant="link"
        no-caret
      >
        <template slot="button-content">
          <b-avatar class="mr-2" />
          <span
            class="d-md-down-none"
            style="min-width: 190px; color: #fff;"
          >{{ AccountName }}&nbsp;</span>
          <i
            class="fa fa-caret-down"
            style="color:#fff;"
          />
        </template>
        <b-dropdown-item href="javascript:void(0)"><i class="fa fa-user text-third"></i> Minha Conta</b-dropdown-item>
        <b-dropdown-item href="javascript:void(0)" @click="onMenuSettings"><i class="fas fa-cogs text-third"></i> Configurações</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          href="javascript:void(0)"
          @click="onSignOut"
        >
          <i class="fas fa-sign-out-alt" /> Sair
        </b-dropdown-item>
      </b-dropdown>

      <li class="pr5 d-md-down-none">
&nbsp;
      </li>
      <!--
      <li class="nav-item d-md-down-none">
        <a class="nav-link navbar-toggler aside-menu-toggler" href="#" @click="asideToggle">&#9776;</a>
      </li>
      -->
    </ul>
  </navbar>
</template>
<script>
import AccountMixin from '@/store/module/account/mixin'
import { JWTService } from '@/shared/services/jwt'
import router from '@/router'
import navbar from './Navbar'

export default {
  name: 'HeaderApp',
  mixins: [AccountMixin],
  data () {
    return {
      display: '',
      imgAvatar: process.env.BaseURL + 'static/img/avatars/02.png'
    }
  },
  components: {
    navbar
  },
  computed: {
    AccountName: function () {
      var result = 'N/D'
      if (this.account && this.account.name) {
        result = this.account.name
      }
      return result
    }
  },
  methods: {
    onSignOut () {
      JWTService.DestroyToken()
      router.replace('/login')
    },
    sidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-hidden')
    },
    sidebarMinimize (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-minimized')
    },
    mobileSidebarToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-mobile-show')
      // this.display = 'none'
    },
    asideToggle (e) {
      e.preventDefault()
      document.body.classList.toggle('aside-menu-hidden')
    },
    toggleFullScreen () {
      if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        // topMenuData.showSmall = true
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        }
      } else {
        //  this.topMenuData.showSmall = false
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    onMenuSettings () {
      this.$emit('OpenSettings')
    }
  }
}
</script>
<style scoped>
.dropdown-item {
  color: #696a6d;
}
.dropdown-item i {
  color: #236192;
}

</style>
