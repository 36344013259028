<template>
  <div class="sidebar">
    <nav class="sidebar-nav">
      <div>&nbsp;</div>
      <ul class="nav" v-if="isMenuDefault">
        <li class="divider" />
        <li class="nav-item">
          <router-link :to="{ name: 'StartPage' }" class="nav-link"><i class="fas fa-home"></i> Página inicial </router-link>
        </li>
        <li class="divider" />
        <li class="nav-title">
          SUPORTE
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Clientes' }" class="nav-link"><i class="fas fa-users"></i> Clientes </router-link>
        </li>
        <li class="nav-item" v-if="false">
          <a href="javascript:void()" class="nav-link" exact><i class="fas fa-headset"></i> Chamados (OS)</a>
        </li>
        <li class="nav-title">
          Comunicação
        </li>
        <router-link tag="li" class="nav-item nav-dropdown" :to="{ path: '/comunicacao/mailing/' }" disabled>
          <div class="nav-link nav-dropdown-toggle" @click="handleClick"><i class="fas fa-mail-bulk"></i> Mailing</div>
          <ul class="nav-dropdown-items">
            <li class="nav-item">
              <router-link :to="{ name: 'Campaign' }" class="nav-link"><i class="fas fa-paper-plane"></i> Campanha </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'CampaignList' }" class="nav-link"><i class="fas fa-address-book"></i> Lista </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'CampaignTemplate' }" class="nav-link"><i class="fas fa-envelope-open-text"></i> Templates </router-link>
            </li>
          </ul>
        </router-link>
      </ul>
      <SidebarSettings v-show="isMenuSetting" v-on:Action="showMenuDefault"/>
    </nav>
  </div>
</template>
<script>
import Roles from '@/shared/utils/roles.js'
import PermissionMixin from '@/store/module/permission/mixin'
import AccountMixin from '@/store/module/account/mixin'
import { actionTypes as AccountActions } from '@/store/module/account/actions'
import SidebarSettings from '@/containers/layout/sidebars/SidebarSettings.vue'

export default {
  name: 'SidebarApp',
  mixins: [ AccountMixin, PermissionMixin ],
  data () {
    return {
      isMenuDefault: true,
      isMenuReport: false,
      isMenuSetting: false,
      roles: Roles
    }
  },
  components: {
    SidebarSettings
  },
  mounted () {
    this[AccountActions.GET_LOGGED]()
      .then((response) => {
      })
  },
  methods: {
    handleClick (e) {
      e.preventDefault()
      e.target.parentElement.classList.toggle('open')
    },
    showMenuReport () {
      this.RemoveMinimizedSidebar()
      this.isMenuDefault = false
      this.isMenuReport = true
      this.isMenuSetting = false
    },
    showMenuDefault () {
      this.RemoveMinimizedSidebar()
      this.isMenuDefault = true
      this.isMenuReport = false
      this.isMenuSetting = false
    },
    showMenuSetting () {
      this.RemoveMinimizedSidebar()
      this.isMenuDefault = false
      this.isMenuReport = false
      this.isMenuSetting = true
    },
    RemoveMinimizedSidebar: function () {
      document.body.classList.remove('sidebar-minimized')
      window.scrollTo(0, 0)
    },
    hasRole (role) {
      return true
      // var result = false
      // if (this.permissions) {
      //   for (var i in this.permissions) {
      //     if (this.permissions[i].role === role) {
      //       result = true
      //     }
      //   }
      // }
      // return result
    }
  }
}
</script>

<style lang="css">
  .nav-link {
    cursor:pointer;
  }
</style>
