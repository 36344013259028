export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  email: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  }
}
