import ActionsForm from '@/shared/mixins/actionsForm'
import ListGrid from './list/list.vue'
import ListAdd from './add/emaillistadd.vue'

export default {
  name: 'EmailingList',
   mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null,
      showNew: false
    }
  },
  components: {
    ListGrid,
    ListAdd
  },
  methods: {
    onNew() {
      this.showNew = true
    },
    onBack() {
      this.showNew = false
    },
    onEdit(obj) {
      this.showNew = true
      setTimeout(() => {
        this.$refs.ListAdd.onEdit(obj)
      }, 200)
    }
  }
}