import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import CompanyRepository from '@/shared/http/repositories/settings/company'
import BranchRepository from '@/shared/http/repositories/settings/branch'
import BranchModel from '@/shared/models/settings/branchmodel'
import BranchForms from './branch-form'

export default {
  name: 'Branch',
  mixins: [ActionsForm],
  data () {
    return {
      data: BranchModel,
      form: BranchForms
    }
  },
  computed: {
    FieldSizeTip: function () {
      return this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
    }
  },
  mounted () {
    this.onLoadAllCompany()
  },
  methods: {
    onLoad () {
      loading.push()
      BranchRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then(this.onResultGrid)
        .catch(this.onException)
    },
    onCreate () {
      loading.push()
      BranchRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      BranchRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      BranchRepository.Delete(obj.id)
        .then(this.onResult)
        .catch(this.onException)
    },
    onLoadAllCompany () {
      loading.push()
      CompanyRepository.GetAll()
        .then((response) => {
          this.form.companyID.options = this.onResultDropdown(response)
        })
        .catch(this.onException)
    }
  }
}
