<template>
  <div class="animated fadeIn">
    <b-modal ref="modal-change-password" title="ALTERAR SENHA DO CLIENTE">
      <template #modal-header>
        <div class="w-100">
          <h5 class="text-bold"><i class="fas fa-key"></i> ALTERAR SENHA DO CLIENTE</h5>
        </div>
      </template>
      <b-container fluid>
        <div class="form-group row">
          <div class="col-md-12">
            <label for="txtName"><small class="text-danger">*</small> Conta:</label>
            <div class="input-group">
              <b-form-input type="text"
                            :placeholder="'Nome da conta do cliente'"
                            v-model="form.name.value"
                            :required="false"
                            :disabled="true"
              ></b-form-input>
            </div>
          </div>
        </div>        
        <div class="form-group row pt-2">
          <div class="col-md-12">
            <label for="txtPassword"><small class="text-info">*</small> {{$lang.Accounts.LabelPassword}}:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
              <b-form-input type="password"
                            :placeholder="$lang.Accounts.PlaceholderEnterPassword"
                            v-model="form.password.value"
                            :size="form.password.maxlength"
                            :required="form.password.required"
                            :state="form.password.state"
                            autocomplete="new-password"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-2 pb-3">
          <div class="col-md-12">
            <label for="txtConfirmation"><small class="text-info">*</small> {{$lang.Accounts.LabelConfirmation}}:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
              <b-form-input type="password"
                            :placeholder="$lang.Accounts.PlaceholderConfirmationPassword"
                            v-model="form.confirmation.value"
                            :size="form.confirmation.maxlength"
                            :required="form.confirmation.required"
                            :state="form.confirmation.state"
                            autocomplete="new-password"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="form-group row pt-3 pb-2" v-if="false">
          <div class="col-md-12">
            <b-form-checkbox
              id="checkbox-1"
              v-model="this.data.sendmail"
              name="checkbox-1"
              value="true"
              unchecked-value="false"
            >
              Enviar a nova senha por email para o cliente?
            </b-form-checkbox>
          </div>
        </div>
      </b-container>
      <template #modal-footer>
        <div class="w-100 text-right">
          <button
            type="button"
            class="btn btn-third btn-sm" 
            @click="onChange"     
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonSave"
          >
            <i class="fa fa-check-circle" /> {{ $lang.Common.Buttons.Save }}
          </button>&nbsp;
          <button
            type="button"
            class="btn btn-default btn-sm text-secundary"
            @click="hide"
            v-b-tooltip.hover
            :title="$lang.Common.Tooltip.ButtonClose"
          >
            <i class="fas fa-times-circle"></i> {{ $lang.Common.Buttons.Close }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { loading, toast } from '@/shared/utils/UIHelper'
import ActionForms from '@/shared/mixins/actionsForm.js'

import AccountRepository from '@/shared/http/repositories/access/account.js'
export default {
  name: 'ModalChangePassword',
  mixins: [
    ActionForms
  ],
  data () {
    return {
      data: null,
      form: {
        name: {
          value: '',
          maxlength: '30',
          required: false,
          state: null
        },
        password: {
          value: '',
          maxlength: '30',
          required: true,
          state: null
        },
        confirmation: {
          value: '',
          maxlength: '30',
          required: true,
          state: null
        }
      }
    }
  },
  methods: {
    onLoad () {}, 
    onShow(account) {
      this.show()
      this.data = account    
      this.data.password = null  
      this.form.name.value = this.data.name     
    },
    show () {
      this.$refs['modal-change-password'].show()
      this.onClear()      
    },
    hide () {
      this.$refs['modal-change-password'].hide()
    },
    onChange () {      
      if (this.form.password.value) {
        if (this.form.password.value == this.form.confirmation.value) {
          this.hide()
          loading.push()
          this.data.password = this.form.password.value.toString()
          AccountRepository.Update(this.data)
            .then((response) => {
              this.onResult(response, true, true)
            })
            .catch((err) => {
              this.onException(err)
            })
        } else {
          toast.warning("ATENÇÃO! A confirmação precisar ser igual a senha!")
        }
      } else {
        toast.warning("ATENÇÃO! Você precisa informar uma senha!")
      }
    },
  }
}
</script>