import APIClient from '@/shared/http/clients/api'

const resourceName = 'emaillistitems'
const APIOnboarding = process.env.VUE_APP_MANAGER

const GetAll = (data = {}, config = {}) => APIClient.get(`${APIOnboarding}/${resourceName}`, data, config)
const GetAllPaging = (limit, index, config = {}) => APIClient.get(`${APIOnboarding}/${resourceName}?limit=${limit}&index=${index}`, config)
const GetById = (id, config = {}) => APIClient.get(`${APIOnboarding}/${resourceName}/${id}`, config)
const Create = (data = {}, config = {}) => APIClient.post(`${APIOnboarding}/${resourceName}`, data, config)
const Update = (data = {}, config = {}) => APIClient.put(`${APIOnboarding}/${resourceName}`, data, config)
const Delete = (id, config = {}) => APIClient.delete(`${APIOnboarding}/${resourceName}/${id}`, config)

const EmailListItemsRepository = {
  GetAll,
  GetAllPaging,
  GetById,
  Create,
  Update,
  Delete
}

export default EmailListItemsRepository
