<template>
  <div class="animated fadeIn">
    <Alerts ref="alert"/>
    <Forms ref="frmGroup"
           :title="this.$lang.Users.TitleForm"
           :showHelp="true"
           :showClearButton="true"
           :showSaveButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtName"><small class="text-danger">*</small> {{$lang.Users.LabelName}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          :placeholder="$lang.Users.PlaceholderEnterName"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtEmail"><small class="text-danger">*</small> {{$lang.Users.LabelEmail}}:</label>
          <div class="input-group">
            <b-form-input type="email"
                          :placeholder="$lang.Users.PlaceholderEnterEmail"
                          v-model="form.email.value"
                          :size="form.email.maxlength"
                          :required="form.email.required"
                          :state="form.email.state"
                          autocomplete="false"
                          @blur.native="onCheckEmail"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtPhone"><small v-show="form.cellPhone.required" class="text-danger">*</small> {{$lang.Users.LabelMobilePhone}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          name="txtPhone"
                          :placeholder="$lang.Users.PlaceholderMobilePhone"
                          v-model="form.cellPhone.value"
                          :size="form.cellPhone.maxlength"
                          :required="form.cellPhone.required"
                          :state="form.cellPhone.state"
                          v-mask="['(##) # ####-####']"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtGroup"><small class="text-danger">*</small> {{$lang.Users.LabelGroup}}:</label>
          <div class="input-group">
            <b-form-select v-model="form.groupID.value" :options="form.groupID.options" class="mb-3" >
              <template slot="first">
                <!-- this slot appears above the options from 'options' prop -->
                <option :value="null">{{$lang.Common.PlaceholderSelect}}</option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12 pt-1">
          <h5 class="text-third"><i class="fa fa-lock"></i> {{$lang.Users.AccessPasswords}}</h5>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label for="txtPassword"><small class="text-info">*</small> {{$lang.Users.LabelPassword}}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
            <b-form-input type="password"
                          :placeholder="$lang.Users.PlaceholderEnterPassword"
                          v-model="form.password.value"
                          :size="form.password.maxlength"
                          :required="form.password.required"
                          :state="form.password.state"
                          autocomplete="new-password"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtConfirmation"><small class="text-info">*</small> {{$lang.Users.LabelConfirmation}}:</label>
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
            <b-form-input type="password"
                          :placeholder="$lang.Users.PlaceholderConfirmationPassword"
                          v-model="form.confirmation.value"
                          :size="form.confirmation.maxlength"
                          :required="form.confirmation.required"
                          :state="form.confirmation.state"
                          autocomplete="new-password"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <small class="text-third"><i class="fa fa-info-circle"></i> {{$lang.Users.PasswordOnlyNewRegistration}}</small>
        </div>
      </div>
    </Forms>
    <Tables ref="tableGrid"
            :columns="[$lang.Users.DataTable.Name,
                       $lang.Users.DataTable.Group,
                       $lang.Common.DataTable.Status,
                       $lang.Common.DataTable.ActionDate,
                       $lang.Common.DataTable.AccountAction,
                       $lang.Common.DataTable.Actions]"
            :showSearch="true"
            :showPrint="false"
            :showDownload="false"
            :showFullscreen="false"
            v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.group ? obj.group.name : ''" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import User from './user.js'
export default User
</script>
