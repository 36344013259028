export default {
  id: null,
  tenantID: null,
  groupID: null,
  name: null,
  cellPhone: null,
  email: null,
  username: null,
  password: null,
  description: null,
  status: null,
  dateAction: null,
  accountIDUpdate: null
}
