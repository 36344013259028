<template>
  <div class="animated fadeIn">
    <Tables
      ref="tableGrid"
      :columns="[
        $lang.Customers.Accounts.DataTable.Name,
        $lang.Customers.Accounts.DataTable.Email,
        $lang.Common.DataTable.Status,
        $lang.Common.DataTable.ActionDate,
        $lang.Common.DataTable.AccountAction,
        $lang.Common.DataTable.Actions
      ]"
      :showSearch="false"
      v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.email" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn IsSlot>
          <i
            class="fas fa-key text-warning icone-grid"
            v-b-tooltip.hover
            :title="$lang.Customers.Accounts.DataTable.Actions.ChangePassword"
            @click="onOpenChangePassword(obj)"
          />
          <i
            class="fas fa-sign-in-alt text-third icone-grid pl-2"
            v-b-tooltip.hover
            :title="$lang.Customers.Accounts.DataTable.Actions.SignIn"
            @click="onSignIn(obj)"
          />
        </TableColumn>
      </tr>
    </Tables>
    <ModalChangePassword ref="ModalChangePassword"/>
  </div>
</template>

<script>
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'
import ActionsForm from '@/shared/mixins/actionsForm'

import CostumerRepository from '@/shared/http/repositories/access/costumer'
import SupportAuthRepository from '@/shared/http/repositories/admin/supportauth'
import ModalChangePassword from './modal/changepassword.vue'

export default {
  name: 'CostumerAccounts',
  mixins: [ActionsForm],
  props: {
    costumer: {
      type: Object,
      required: true
    }
  },
  components: {
    ModalChangePassword
  },
  watch: {
    'costumer.id': function () {
      this.onLoad()
    }
  },
  methods: {
    onLoad() {
      this.onLoadAllCostumerAccounts(this.costumer.id)
    },
    onLoadAllCostumerAccounts (tenantID) {
      loading.push()
      CostumerRepository.GetAllAccountsPaging(tenantID, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onSignIn(account) {
      loading.push()
      SupportAuthRepository.SignIn(account.id)
        .then(res => {
          const { code, data } = res.data

          if (code === 200) {
            const { token } = data
            window.open(`${process.env.VUE_APP_SALUDIGITAL}/support/auth?token=${encodeURIComponent(token)}`)
          }

          loading.pop()
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onOpenChangePassword (account) {
      this.$refs.ModalChangePassword.onShow(account)
    }
  }
}
</script>

<style>
.text-icon {
  font-size: 18px;
}
</style>