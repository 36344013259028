<template>
  <div class="animated fadeIn">
    <Tables
      ref="tableGrid"
      :columns="[
        'Nome',
        'Usar Plataforma',
        'Qtde. E-mail',
        $lang.Common.DataTable.Status,
        $lang.Common.DataTable.ActionDate,
        $lang.Common.DataTable.AccountAction,
        $lang.Common.DataTable.Actions
      ]"
      :showSearch="false"
      :showNew="true"
      v-on:Load="onLoad"
      v-on:New="$emit('New')"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.usingAccount ? 'SIM' : 'NÃO'" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.quantity" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import EmailList from './list.js'
export default EmailList
</script>