import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import TemplateContactTypeRepository from '@/shared/http/repositories/settings/templatecontacttype.js'
import TemplateModel from '@/shared/models/settings/template/templatemodel.js'
import TemplateContactForms from './contacttype-form'

export default {
  name: 'group',
  mixins: [ActionsForm],
  data () {
    return {
      data: TemplateModel,
      form: TemplateContactForms
    }
  },
  methods: {
    onLoad () {
      loading.push()
      TemplateContactTypeRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      TemplateContactTypeRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      TemplateContactTypeRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      TemplateContactTypeRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
