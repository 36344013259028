<template>
  <div class="animated fadeIn" @keyup.enter="onFilter">
    <div class="form-group row">
      <div class="col-md-12">
        <label> Cliente:</label>
        <div class="input-group mb-3">
          <b-form-input type="text"
                        :placeholder="'Digite o nome do Cliente'"
                        v-model="form.name.value"
                        :state="form.name.state"
                        :required="true"
                        autofocus
          />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-third w-100" v-on:click="onFilter">Filtrar</button>
      </div>
      <div class="col-md-12 pt-2">
        <button type="button" class="btn btn-default w-100" v-on:click="onClearFilters">Limpar Filtros</button>
      </div>
    </div>
    <div class="form-group row" style="margin-top: 200px;">&nbsp;</div>
  </div>
</template>
<script>
import CustomerSearch from './search.js'
export default CustomerSearch
</script>
