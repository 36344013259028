export default {
  organization: {
    value: '',
    maxlength: '150',
    required: true,
    state: null
  },
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  email: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  password: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  confirmPassword: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  }
}