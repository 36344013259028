<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12 pt-3 pb-3 pl-4">
        <h3><i class="fas fa-users"></i> Clientes</h3>
      </div>
    </div>
    <b-tabs
      card
      v-model="tabIndex"
    >
      <b-tab no-body title="Clientes" active>
        <template slot="title">
          <i class="fa fa-user-friends text-third"></i><span class="d-md-down-none pl-1 text-third"> Clientes</span>
        </template>
        <Tables
          ref="tableGrid"
          :columns="[
            $lang.Customers.DataTable.Name,
            $lang.Common.DataTable.Status,
            $lang.Common.DataTable.ActionDate,
            $lang.Common.DataTable.AccountAction,
            $lang.Common.DataTable.Actions
          ]"
          :showSearch="true"
          :showNew="true"
          v-on:Load="onLoad"
          v-on:Search="onShowSearch"
          v-on:New="onNew"
        >
          <tr v-for="obj in this.objList" :key="obj.id">
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
            <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
            <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
            <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
            <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
          </tr>
        </Tables>
      </b-tab>
      <b-tab title="Detalhes" :disabled="edit === null">
        <template slot="title">
          <i class="fa fa-clipboard-list text-third"></i><span class="d-md-down-none pl-1 text-third"> Detalhes</span>
        </template>
        <CostumerDetails
          v-if="isEditing"
          :costumer="edit"
        />
      </b-tab>
      <b-tab no-body title="Assinatura" :disabled="edit === null">
        <template slot="title">
          <i class="fa fa-file-invoice-dollar text-third"></i><span class="d-md-down-none pl-1 text-third"> Assinatura</span>
        </template>
      </b-tab>
    </b-tabs>
    <NewCostumer
      ref="newCostumer"
      v-on:Created="onCreateCostumer"
    />
  </div>
</template>
<script>
import Tools from '@/components/tools/Tools.js'
import { loading } from '@/shared/utils/UIHelper'
import ActionsForm from '@/shared/mixins/actionsForm'

import CostumerDetails from './details/details.vue'
import NewCostumer from './new/new.vue'
import CustumerSearch from './search/search.vue'

import CostumerRepository from '@/shared/http/repositories/access/costumer'

import CostumerModel from '@/shared/models/access/costumermodel'
import CostumerForms from './costumer-form'

export default {
  name: 'Customers',
  mixins: [ActionsForm],
  components: {
    CostumerDetails,
    NewCostumer
  },
  data () {
    return {
      data: CostumerModel,
      form: CostumerForms,
      tabIndex: 0,
      edit: null,
      filters: null,
    }
  },
  beforeDestroy () {
    this.$eventHub.off('Filter')
    Tools.MinimizedASidebar()
  },
  computed: {
    isEditing: function () {
      return this.edit !== null
    }
  },
  methods: {
    onLoad() {
      this.onLoadAllCostumers()
      this.$eventHub.on('Filter', ({ filters }) => {
        this.filters = filters
        this.$refs.tableGrid.Clear()
        this.$nextTick(() => {
          this.onLoadAllCostumers()
        })
      })
    },
    onShowSearch () {
      let aside = {
        title: 'Pesquisar',
        self: this,
        component: CustumerSearch
      }
      this.$eventHub.emit('Search', aside)
    },
    onLoadAllCostumers () {
      loading.push()
      if(this.filters) {
        CostumerRepository.Search(this.filters, this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then(this.onResultGrid)
          .catch(this.onException)
      } else {
        CostumerRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
          .then(this.onResultGrid)
          .catch(this.onException)
      }
    },
    onEdit (obj) {
      this.$eventHub.off('Filter')
      Tools.MinimizedASidebar()
      this.edit = obj

      this.$nextTick(() => {
        this.tabIndex = 1
      })
    },
    onUpdate () {
      loading.push()
      if (this.data.password) {
        this.data.password = this.data.password.toString()
      } else { this.data.password = '' }

      CostumerRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      CostumerRepository.Delete(obj.id)
        .then(this.onResult)
        .catch(this.onException)
    },
    onNew () {
      this.$refs.newCostumer.show()
    },
    onCreateCostumer () {
      this.$refs.newCostumer.close()
      this.onLoadAllCostumers()
    }
  }
}
</script>
