import APIClient from '@/shared/http/clients/api'

const resourceName = 'supportauth'
const APIAccess = process.env.VUE_APP_MANAGER

const SignIn = (accountID, config = {}) => APIClient.post(`${APIAccess}/${resourceName}/${accountID}/authorize`, config)

const SupportAuthRepository = {
  SignIn
}

export default SupportAuthRepository
