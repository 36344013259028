export const JWT_KEY = 'X_SALUDIGITAL_SUPPORT_ACCESS_TOKEN'

/**
 * @function SaveToken
 * @description Armazena um JWT no localStorage.
 * @param {string} token O JWT para ser armazenado no localStorage.
 * @returns {void}
 */
const SaveToken = (token) => {
  window.localStorage.setItem(JWT_KEY, token)
}

/**
 * @function GetToken
 * @description Obtém o JWT armazenado no localStorage.
 * @returns {string} O JWT armazenado no localStorage.
 */
const GetToken = () => window.localStorage.getItem(JWT_KEY)

/**
 * @function GetTokenHeader
 * @description Obtém o header do JWT armazenado no localStorage.
 * @returns {Object} O header do JWT armazenado no localStorage.
 */
const GetTokenHeader = () => {
  const rawToken = GetToken()
  const rawHeader = rawToken.split('.')[0]
  const decodedRawHeader = atob(rawHeader)
  const decodedHeader = JSON.parse(decodedRawHeader)
  return decodedHeader
}

/**
 * @function GetTokenPayload
 * @description Obtém o payload do JWT armazenado no localStorage.
 * @returns {Object} O payload do JWT armazenado no localStorage.
 */
const GetTokenPayload = () => {
  const rawToken = GetToken()
  const rawPayload = rawToken.split('.')[1]
  const decodedRawPayload = atob(rawPayload)
  const decodedPayload = JSON.parse(decodedRawPayload)
  return decodedPayload
}

/**
 * @function DestroyToken
 * @description Destrói o JWT armazenado no localStorage.
 * @returns {void}
 */
const DestroyToken = () => window.localStorage.removeItem(JWT_KEY)

export const JWTService = {
  SaveToken,
  GetToken,
  GetTokenHeader,
  DestroyToken,
  GetTokenPayload
}

export default JWTService
