import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import CompanyRepository from '@/shared/http/repositories/settings/company'
import CompanyModel from '@/shared/models/settings/companymodel'
import CompanyForms from './company-form'

export default {
  name: 'Company',
  mixins: [ActionsForm],
  data () {
    return {
      data: CompanyModel,
      form: CompanyForms
    }
  },
  computed: {
    FieldSizeTip: function () {
      return this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
    }
  },
  methods: {
    onLoad () {
      loading.push()
      CompanyRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then(this.onResultGrid)
        .catch(this.onException)
    },
    onCreate () {
      loading.push()
      CompanyRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      CompanyRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      CompanyRepository.Delete(obj.id)
        .then(this.onResult)
        .catch(this.onException)
    }
  }
}
