import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import TemplateLeadOriginRepository from '@/shared/http/repositories/settings/templateleadorigin.js'
import TemplateModel from '@/shared/models/settings/template/templatemodel.js'
import TemplateLeadOriginForms from './leadorigin-form'

export default {
  name: 'leadorigin',
  mixins: [ActionsForm],
  data () {
    return {
      data: TemplateModel,
      form: TemplateLeadOriginForms
    }
  },
  methods: {
    onLoad () {
      loading.push()
      TemplateLeadOriginRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onCreate () {
      loading.push()
      TemplateLeadOriginRepository.Create(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      TemplateLeadOriginRepository.Update(this.data)
        .then(this.onResult)
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      TemplateLeadOriginRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}
