<template>
  <div class="animated fadeIn">
    <div class="container">
      <div class="row pt-4">
        <div class="col-12 text-center">Bem-vindo ao Painel <strong> {{AccountName}}</strong>!</div>
        <div class="col-12 text-center pt-4"><h3>Selecione a área desejada!</h3></div>
      </div>
      <div class="row pt-4 pb-3">
        <div class="col-12 text-center">
          <b-card-group deck>
            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" @click="onGoToCustomers">
              <i class="fas fa-users pt-4 text-third" style="font-size: 100px" />
              <h4 class="pt-3 text-default">Clientes</h4>
              <div>Gestão de clientes</div>
            </b-card>

            <b-card class="text-center pb-2 pointer" style="max-width: 20rem;" @click="onGoToMailing">
              <i class="fas fa-mail-bulk pt-3 text-third" style="font-size: 100px" />
              <h4 class="pt-4 text-default">Campanha</h4>
              <div>Campanha para envio de e-mail</div>
            </b-card>
          </b-card-group>
        </div>
      </div>
      <div class="row pt4">
        <div class="col-12 text-center">
          <img src="/static/img/bg/developer_activity_re_39tg.svg" style="position: absolute; display: inline-block; width: 60%; opacity: 0.05; right: -35%">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountMixin from '@/store/module/account/mixin'
import Tools from '@/components/tools/Tools.js'

export default {
  name: 'StartPage',
  mixins: [AccountMixin],
  computed: {
    AccountName: function () {
      var name = ''
      if (this.account && this.account.name) {
        name = Tools.capitalize(this.account.name.toLowerCase())
      }
      return name
    }
  },
  mounted () {
    Tools.MinimizedSidebar()
  },
  methods: {
    onGoToCustomers () {
      this.$router.push({ name: 'Clientes' })
    },
    onGoToMailing () {
      this.$router.push({ name: 'Campaign' })
    }
  }
}
</script>
<style scoped>
.card {
  border-radius: 11px;
  box-shadow: 0 0 1em #ccc;
}
h4 {
  font-weight: bold;
}
</style>
