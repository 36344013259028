export default {
  id: null,
  companyID: null,
  name: null,
  cnpj: null,
  description: null,
  status: null,
  dateAction: null,
  AccountUserIDAction: null
}
