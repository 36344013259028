import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'

import EmailListRepository from '@/shared/http/repositories/admin/emaillist.js'
import EmailListModel from '@/shared/models/admin/emaillist.js'

export default {
  name: 'EmailList',
   mixins: [ActionsForm],
  data () {
    return {
      data: EmailListModel,
      form: null
    }
  },
  methods: {
    onLoad() {
      loading.push()
      EmailListRepository.GetAllPaging(this.$refs.tableGrid.pagingLimit, this.$refs.tableGrid.pagingIndex)
        .then((response) => {
          this.onResultGrid(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onEdit(obj) {
      this.$emit('Edit', obj)
    },
    onUpdate () {
      loading.push()
      EmailListRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
        })
        .catch(this.onException)
    },
    onDelete (obj) {
      loading.push()
      EmailListRepository.Delete(obj.id)
        .then((response) => {
          this.onResult(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}