<template>
  <div class="animated fadeIn">
    <Tables
      ref="tableGrid"
      :columns="[
        'Dt. Criação',
        'Título',
        'Template',
        'Lista',
        'Dt. Envio',
        'Situação',
        $lang.Common.DataTable.Status,
        $lang.Common.DataTable.ActionDate,
        $lang.Common.DataTable.AccountAction,
        $lang.Common.DataTable.Actions
      ]"
      :showSearch="false"
      :showNew="true"
      v-on:Load="onLoad"
      v-on:New="$emit('New')"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateCreated" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.emailTemplate ? obj.emailTemplate.name : ''" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.emailList ? obj.emailList.name : ''" />
        <TableColumn :IsDateOnly="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateSend" />
        <TableColumn :IsCustom="true" v-on:Edit="onEdit(obj)">
          <div v-if="obj.situation === 0"><a href="javascript:void(0)" class="text-danger text-bold">PENDENTE</a></div>
          <div v-else-if="obj.situation === 1"><a href="javascript:void(0)" class="text-warning text-bold">EM ANDAMENTO</a></div>
          <div v-else-if="obj.situation === 2"><a href="javascript:void(0)" class="text-success text-bold">CONCLUÍDO</a></div>
          <div v-else>&nbsp;</div>
        </TableColumn>
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import CampaignList from './campaignlist.js'
export default CampaignList
</script>
