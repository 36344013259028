<template>
  <div class="animated fadeIn">
    <div class="form-group row pt-4 pb-2">
      <div class="col-md-12">
        <h5 class="text-third"><i class="fas fa-address-book"></i> Lista de Contatos</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label for="txtName"><small class="text-danger" v-if="form.name.required">*</small> Nome:</label>
        <div class="input-group">
          <b-form-input type="text"
                        :placeholder="'Nome do contato'"
                        v-model="form.name.value"
                        :size="form.name.maxlength"
                        :required="form.name.required"
                        :state="form.name.state"
          ></b-form-input>
        </div>
      </div>
      <div class="col-md-7">
        <label for="txtName"><small class="text-danger" v-if="form.email.required">*</small> Email:</label>
        <div class="input-group">
          <b-form-input type="text"
                        id="txtName"
                        name="txtName"
                        :placeholder="'E-mail do contato'"
                        v-model="form.email.value"
                        :size="form.email.maxlength"
                        :required="form.email.required"
                        :state="form.email.state"
          ></b-form-input>
          <button
            type="button"
            class="btn btn-third btn-sm ml-2 mr-2"
            v-b-tooltip.hover
            :title="'Clique para adicionar o Contato a Lista'"
          >
            <i class="fa fa-plus-circle" />
          </button>
        </div>
      </div>
    </div>
    <Tables
      ref="tableGrid"
      :columns="[
        'Nome',
        'Emails',
        $lang.Common.DataTable.Status,
        $lang.Common.DataTable.ActionDate,
        $lang.Common.DataTable.AccountAction,
        $lang.Common.DataTable.Actions
      ]"
      v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.email" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>
<script>
import EmailListItems from './emaillistitems.js'
export default EmailListItems
</script>