import UserRepository from '@/shared/http/repositories/access/user.js'
import { toast, loading } from '@/shared/utils/UIHelper'
import AuthService from '@/shared/services/auth'
import { JWTService } from '@/shared/services/jwt'
import * as mutation from './mutationTypes'
import { actionTypes as action } from './actionTypes'

export const actions = {
  async [action.SIGN_IN] ({ commit }, credentials) {
    return AuthService.signIn(credentials)
      .then((res) => {
        if (res.success) {
          commit(mutation.SET_JWT, JWTService.GetToken())
          return Promise.resolve({ success: true })
        }

        JWTService.DestroyToken()
        commit(mutation.SET_JWT, null)

        // return Promise.reject({ success: false })
        return Promise.reject(new Error('Not authorized'))
      })
      .catch(err => Promise.reject(err))
  },

  /* eslint-disable-next-line */
  [action.SIGN_OUT] (data = {}, router) {
    AuthService.signOut()
    router.replace({ name: 'SignIn' })
  },

  [action.FETCH_USER_INFO] ({ state, commit }, force) {
    if ((force || !state.userInfo) && JWTService.GetToken()) {
      loading.push()
      console.log(JWTService.GetTokenPayload())
      UserRepository.Get(JWTService.GetTokenPayload().sid)
        .then((res) => {
          if (res.data.code >= 0) {
            commit(mutation.SET_USER_INFO, res.data.data)
            loading.pop()
          } else {
            JWTService.DestroyToken()
            toast.error('Sua sessão foi finalizada!')
            loading.pop()
          }
        })
        .catch(() => {
          JWTService.DestroyToken()
          toast.error('Falha ao obter informações do conta.')
          loading.pop()
          // this.router.push({ name: 'SignIn' })
        })
    }
  }

}

export default actions
