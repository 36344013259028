<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12 pt-3 pb-3 pl-4">
        <h3><i class="fas fa-paper-plane"></i> Campanha</h3>
      </div>
    </div>
    <CampaignAdd ref="CampaignAdd" v-on:Back="onBack" v-if="showNew"/>
    <CampaignList ref="CampaignList" v-on:New="onNew" v-on:Edit="onEdit" v-else/>
  </div>
</template>
<script>
import Campaing from './campaign.js'
export default Campaing
</script>
