export default {
  id: null,
  tenantID: null,
  templateCategoryID: null,
  name: null,
  description: null,
  status: null,
  dateAction: null,
  accountIDUpdate: null
}
