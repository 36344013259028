// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Lang from 'vue-lang'
import languages from './languages'
import moment from 'moment'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vSelect from 'vue-select'
import VeeValidate from 'vee-validate'
import VeeValidateConfig from './config/veeValidate'
import ToastrInstaller from './components/UI/toastr/Toastr'
import './shared/mixins'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import money from 'v-money'
import {ColorPicker, ColorPanel} from 'one-colorpicker'
import EventHub from 'vue-event-hub'

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css'

Vue.use(ColorPanel)
Vue.use(ColorPicker)

require('../scss/style.scss')

Vue.use(EventHub)
Vue.use(Lang, { lang: 'ptBr', locales: languages })
Vue.prototype.moment = moment
Vue.use(BootstrapVue)
Vue.use(VeeValidate, VeeValidateConfig)
Vue.component('v-select', vSelect)
ToastrInstaller.Install()
Vue.component('autocomplete', VueBootstrapTypeahead)
Vue.use(money)
Vue.use(VueFlatPickr)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  render: h => h(App)
})
