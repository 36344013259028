<template>
  <div class="app flex-row align-items-center bg-img">
    <div class="login-block-logo">
      <img
        src="/static/img/logotipo/SaluDigitalBranca02.png"
        style="width: 240px;"
      >
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card-group mb-0">
            <div class="card p-4">
              <div class="card-block">
                <h1>Redefinir Senha</h1>
                <p class="text-muted">
                  Digita uma nova senha e clique enviar!
                </p>
                <div class="input-group mb-4">
                  <span class="input-group-addon"><i class="icon-lock" /></span>
                  <b-form-input
                    type="password"
                    id="password"
                    name="password"
                    :placeholder="$lang.Login.NewPassword"
                    v-model="password"
                    data-vv-as="senha"
                    v-validate="'required'"
                    aria-describedby="passwordErrorFeedback"
                  />
                </div>
                <div class="row">
                  <div class="col-12 pb-4">
                    <div class="required-field-bottom">
                      <small class="pl-2 text-muted">
                        A senha deve conter 8 a 10 digitos!
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <router-link
                      :to="{ name: 'SignIn' }"
                      class="btn btn-link px-0"
                    >
                      <i class="fa fa-home" /> Home
                    </router-link>
                  </div>
                  <div class="col-6 text-right">
                    <button
                      type="button"
                      class="btn btn-third px-4"
                      @click="onResetPassword"
                    >
                      <i class="fa fa-check-circle" /> Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center pt-3 login-version">
        <a
          href="http://jeugenio.com.br"
          target="_blank"
          style="color: #fff;"
        >Powered by JEUGENIO IT &nbsp;-&nbsp; {{ $lang.Login.LabelVersion }} {{ version }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import ChangePassword from './ChangePassword.js'

export default ChangePassword
</script>
