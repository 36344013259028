<template>
  <div class="animated fadeIn">
    <Forms ref="frmTemplate"
           :title="'CADASTRO DE TEMPLATE'"
           :showClearButton="true"
           :showSaveButton="true"
           :showBackButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
           v-on:Back="$emit('Back')"
    >
      <div class="form-group row">
        <div class="col-md-12">
          <label for="txtName"><small class="text-danger">*</small> Nome:</label>
          <div class="input-group">
            <b-form-input type="text"
                          id="txtName"
                          name="txtName"
                          :placeholder="'Nome do template'"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-5">
          <label for="txtDescription"> Template:</label>
          <b-form-textarea id="txtDescription"
                           name="txtDescription"
                           v-model="form.description.value"
                           :placeholder="'Digite o cole o template em HTML'"
                           :rows="form.description.rows"
                           :size="form.description.maxlength"
                           :noResize="form.description.noResize"
                           style="background-color: #1b282f; color: #7cecbf;"
          ></b-form-textarea>
        </div>
        <div class="col-md-7">
          <label for="txtDescription"> Preview:</label>
          <div style="border: solid 1px #ccc;min-width: 100%;min-height: 543px; max-height: 543px; padding: 7px; overflow: scroll;"> 
            <div v-html="form.description.value"></div>
          </div>
        </div>
      </div>
    </Forms>
  </div>
</template>
<script>
import TemplateAdd from './templateadd.js'
export default TemplateAdd
</script>
