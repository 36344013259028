import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import Tools from '@/components/tools/Tools.js'

import EmailListItemsRepository from '@/shared/http/repositories/admin/emaillistitems.js'
import EmailListItemsModel from '@/shared/models/admin/emaillistitems.js'
import EmailListItemsForms from './emailistitems-form.js'

export default {
  name: 'EmailListAdd',
  mixins: [ActionsForm],
  data () {
    return {
      data: EmailListItemsModel,
      form: EmailListItemsForms
    }
  },
  methods:{
    onCreate () {
      loading.push()
      EmailListItemsRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      EmailListItemsRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    }
  }
}