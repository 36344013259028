<template>
  <div class="app">
    <AppHeader ref="header" v-on:OpenSettings="onOpenSettings" />
    <div class="app-body">
      <Sidebar ref="Sidebar" />
      <main class="main">
        <div
          v-if="removeContainer"
          class="h-100"
        >
          <router-view />
        </div>
        <div
          v-else
          class="h-100"
        >
          <breadcrumb :list="list" />
          <div
            class="container-fluid h-100"
            style="overflow: hidden;"
          >
            <router-view />
          </div>
        </div>
      </main>
      <AppAside />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/containers/layout/Header'
import Sidebar from '@/containers/layout/Sidebar'
import AppAside from '@/containers/layout/Aside'
import AppFooter from '@/containers/layout/Footer'
import Breadcrumb from '@/containers/layout/Breadcrumb'

import PermissionMixin from '@/store/module/permission/mixin'
import { actionTypes as PermissionActions } from '@/store/module/permission/actions'

export default {
  name: 'Full',
  mixins: [ PermissionMixin ],
  components: {
    AppHeader,
    Sidebar,
    AppAside,
    AppFooter,
    Breadcrumb
  },
  computed: {
    name () {
      return this.$route.name
    },

    list () {
      return this.$route.fullPath.split('/')
      // return this.$route.matched
    },

    removeContainer () {
      return this.$route.meta && this.$route.meta.hasOwnProperty('removeContainer') && this.$route.meta.removeContainer
    }
  },
  data () {
    return {
      meta: null
    }
  },
  mounted () {
    this[PermissionActions.GET_ALL_PERMISSIONS]()
      .then(() => {
      })

    this.meta = this.$route.meta
  },
  watch: {
    $route () {
      if (this.$route.meta && this.$route.meta.hasOwnProperty('removeContainer')) {
        this.meta = this.$route.meta
      } else {
        this.meta = null
      }
      this.$forceUpdate()
    }
  },
  methods: {
    routeIs(route) {
      return this.$route.name === route
    },
    onOpenSettings () {
      this.$refs.Sidebar.showMenuSetting()
    }
  }
}
</script>
