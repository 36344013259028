<template>
  <div class="animated fadeIn">
    <Forms ref="frmTemplate"
           :title="'CADASTRO DE LISTA DE EMAIL'"
           :showClearButton="true"
           :showSaveButton="true"
           :showBackButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
           v-on:Back="$emit('Back')"
    >
      <div class="form-group row">
        <div class="col-md-12">
          <label for="txtName"><small class="text-danger">*</small> Nome:</label>
          <div class="input-group">
            <b-form-input type="text"
                          id="txtName"
                          name="txtName"
                          :placeholder="'Nome da Lista'"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row pt-2">
        <div class="col-md-12">
          <label for="txtDescription"> Observação:</label>
          <b-form-textarea id="txtDescription"
                           name="txtDescription"
                           v-model="form.description.value"
                           :placeholder="'Digite uma observação para usa Lista de Email'"
                           :rows="form.description.rows"
                           :size="form.description.maxlength"
                           :noResize="form.description.noResize"
          ></b-form-textarea>
          <small class="small-help"><i class="fa fa-info-circle"></i> <span v-html="FieldSizeTip"></span>.</small>
        </div>
      </div>
      <div class="form-group row pt-2 pb-2">
        <div class="col-md-12">
          <b-form-checkbox
            v-model="form.usingAccount.value"
            value="true"
            unchecked-value="false"
          >
            Usar contas de clientes da plataforma  <i class="fas fa-users"></i>
          </b-form-checkbox>
        </div>
      </div>
      <EmailListItems ref="EmailListItems" />
    </Forms>
  </div>
</template>
<script>
import EmailListAdd from './emaillistadd.js'
export default EmailListAdd
</script>
