export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  description: {
    value: '',
    maxlength: '500',
    required: false,
    rows: '2',
    noResize: true
  },
  usingAccount: {
    value: false,
    required: false,
    state: null
  }
}
