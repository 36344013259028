import Tools from '@/components/tools/Tools.js'
import Alerts from '@/components/forms/Alerts'
import { toast, loading } from '@/shared/utils/UIHelper'
import { mask } from 'vue-the-mask'
import Forms from '@/components/forms/Forms'
import Tables from '@/components/forms/Tables'
import TableColumn from '@/components/forms/TableColumn'
import { JWTService } from '@/shared/services/jwt'
import PermissionMixin from '@/store/module/permission/mixin'
import Roles from '@/shared/utils/roles.js'
import Guid from '@/shared/utils/guid.js'
import Datepicker from '@hokify/vuejs-datepicker'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

export default {
  name: 'actionForms',
  mixins: [ PermissionMixin ],
  data () {
    return {
      roles: Roles,
      data: null,
      form: null,
      objList: null,
      error: null,
      polling: null,
      dateTickerPickerConfig: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i',
        locale: Portuguese
      }
    }
  },
  directives: {mask},
  components: {
    Alerts,
    Forms,
    Tables,
    TableColumn,
    Guid,
    Datepicker
  },
  computed: {
    FieldSizeTip: function () {
      var result = ''
      if (this.form.hasOwnProperty('description')) {
        result = this.$lang.Common.Tips.MaximumSize.replace('@SIZE@', this.form.description.maxlength)
      }
      return result
    }
  },
  created () {
  },
  mounted () {
    Tools.MinimizedSidebar()
    this.onClear()
    this.onLoad()
  },
  methods: {
    onClear () {
      this.onToClear()
    },
    onToClear () {
      Tools.ClearDataForms(this.form, this.data)
      if (this.$refs.alert) {
        this.$refs.alert.Close()
      }
    },
    onEdit (obj) {
      loading.push()
      Tools.SetValueInForm(this.form, this.data, obj)
      loading.pop()
    },
    onSave () {
      var self = this
      if (Tools.ValidateForms(this.form, this.data, self, toast)) {
        // Set default data
        this.data.dateAction = this.moment().format('YYYY-MM-DDThh:mm:ss')
        this.data.accountIDUpdate = JWTService.GetTokenPayload().AccountID
        if (this.data.hasOwnProperty('tenantID')) {
          this.data.tenantID = JWTService.GetTokenPayload().tenantID
        }

        if (this.data) {
          if (this.data.id !== null && this.data.id !== Guid.empty) {
            this.data.status = parseInt(this.data.status)
            this.onUpdate()
          } else {
            this.data.id = Guid.empty
            this.data.status = 1
            this.onCreate()
          }
        } else {
          toast.error(this.$lang.Common.NoRecordInformed)
        }
      }
    },
    onValidationQuery (response) {
      if (response.data.data !== null) {
        toast.success(this.$lang.Common.Query.Valid)
        this.onValidationSuccess(response.data.data)
      } else {
        toast.error(this.$lang.Common.Query.Invalid)
        this.onValidationError()
      }
    },
    onValidateQuery () {
      console.log('Warning: Override method in your view implementation onValidateQuery()')
    },
    onSearch () {
      console.log('Warning: Override method in your view implementation onSearch()')
    },
    onLoad () {
      console.log('Warning: Override method in your view implementation onLoad()')
    },
    onCreate () {
      console.log('Warning: Override method in your view implementation onCreate()')
    },
    onUpdate () {
      console.log('Warning: Override method in your view implementation onUpdate()')
    },
    onChange (obj) {
      for (var key in this.data) {
        if (obj.hasOwnProperty(key)) {
          this.data[key] = obj[key]
        }
      }
      if (this.data.status === 1) {
        this.data.status = 0
      } else {
        this.data.status = 1
      }
      this.onUpdate()
    },
    onResultObj (response) {
      if (response.data.code >= 0) {
        return response.data.data
      } else {
        toast.error(this.$lang.Common.CouldNotLoadRegister)
        return null
      }
      // loading.pop()
    },
    onResultSimple (response) {
      this.onResult(response, true, true)
    },
    onResult (response, IsNotLoad, IsNotClear) {
      if (response.data.code >= 0) {
        if (!IsNotClear) {
          this.onClear()
        }
        // this.$refs.alert.ShowSuccess()
        toast.success(this.$lang.Common.Alerts.Success)
        if (!IsNotLoad) {
          this.onLoad()
        }
      } else {
        // this.$refs.alert.ShowError()
        toast.error(this.$lang.Common.Alerts.Error)
      }
      loading.pop()
    },
    onResultGrid (response, tableGridRef) {
      if (response.data.code >= 0) {
        this.objList = response.data.data
        if (tableGridRef) {
          tableGridRef.LoadAllByData(response)
        } else {
          this.$refs.tableGrid.LoadAllByData(response)
        }
      } else {
        toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      loading.pop()
    },
    onStartTotalGrid (tableGridRef, total) {
      tableGridRef.LoadStartTotalGrid(total)
    },
    onException (err) {
      console.log(err)
      if (err.message === 'Unauthorized') {
        toast.error(this.$lang.Common.SessionEnded)
      } else {
        toast.error(this.$lang.Common.MicroserviceUnavailable)
      }
      loading.pop()
    },
    onResultDropdown (response, hasOpt) {
      var result = []
      if (response.data.code >= 0) {
        var data = response.data.data
        for (var i in data) {
          if (data[i].status) {
            if (hasOpt) {
              result.push({ 'text': ' &nbsp;&nbsp; ' + data[i].name, 'value': data[i].id })
            } else {
              result.push({ 'text': data[i].name, 'value': data[i].id })
            }
          } else {
            result.push({ 'text': data[i].name, 'value': data[i].id, disabled: true })
          }
        }
      } else {
        toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      loading.pop()

      return result
    },
    onSetDropdown (data, hasOpt) {
      var result = []

      if (data && data.length > 0) {
        for (var i in data) {
          if (data[i].status) {
            if (hasOpt) {
              result.push({ 'text': ' &nbsp;&nbsp; ' + data[i].name, 'value': data[i].id })
            } else {
              result.push({ 'text': data[i].name, 'value': data[i].id })
            }
          } else {
            result.push({ 'text': data[i].name, 'value': data[i].id, disabled: true })
          }
        }
      }

      return result
    },
    onResultSelect2 (response) {
      var result = []
      if (response.data.code >= 0) {
        var data = response.data.data
        for (var i in data) {
          if (data[i].status) {
            result.push({ 'label': data[i].name, 'value': data[i].id })
          }
        }
      } else {
        toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      loading.pop()

      return result
    },
    onResultAutoComplete (response) {
      var result = []
      if (response.data.code >= 0) {
        var data = response.data.data
        for (var i in data) {
          if (data[i].alias) {
            result.push({ 'text': data[i].alias, 'value': data[i] })
          } else {
            result.push({ 'text': data[i].name, 'value': data[i] })
          }
        }
      } else {
        toast.error(this.$lang.Common.CouldNotLoadRegister)
      }
      loading.pop()

      return result
    },
    hasRole (role) {
      var result = false
      if (this.permissions) {
        for (var i in this.permissions) {
          if (this.permissions[i].role === role) {
            result = true
          }
        }
      }
      return result
    },
    onToUpper (e, field) {
      if (field.value) {
        field.value += e.key.toUpperCase()
      } else {
        field.value = e.key.toUpperCase()
      }
      e.preventDefault()
    },
    onToLower (e, field) {
      if (field.value) {
        field.value += e.key.toLowerCase()
      } else {
        field.value = e.key.toLowerCase()
      }
      e.preventDefault()
    }
  }
}
