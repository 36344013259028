import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import Tools from '@/components/tools/Tools.js'

import EmailListRepository from '@/shared/http/repositories/admin/emaillist.js'
import EmailTemplateRepository from '@/shared/http/repositories/admin/emailtemplate.js'
import CampaignRepository from '@/shared/http/repositories/admin/campaign.js'
import CampaignModel from '@/shared/models/admin/campaign.js'
import CampaignAddForms from './campaigndd-form.js'

export default {
  name: 'CampaingAdd',
  mixins: [ActionsForm],
  data () {
    return {
      data: CampaignModel,
      form: CampaignAddForms,
      dateTime: {
        date: null,
        hour: null
      }
    }
  },
  created() {
    this.onLoadAllEmailList()
    this.onLoadAllEmailTemplate()
  },
  methods:{
    onCreate () {
      loading.push()      
      this.data.dateCreated = this.moment().format('YYYY-MM-DDThh:mm:ss')
      CampaignRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      CampaignRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    },
    onLoadAllEmailList() {
      loading.push()
      EmailListRepository.GetAll()
        .then((response) => {
          this.form.emailListID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    },
    onLoadAllEmailTemplate() {
      loading.push()
      EmailTemplateRepository.GetAll()
        .then((response) => {
          this.form.emailTemplateID.options = this.onResultDropdown(response)
        })
        .catch((err) => {
          this.onException(err)
        })
    }
  }
}