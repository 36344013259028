import ActionsForm from '@/shared/mixins/actionsForm'

import CampaignList from './list/campaignlist.vue'
import CampaignAdd from './add/campaignadd.vue'

export default {
  name: 'Campaign',
  mixins: [ActionsForm],
  data () {
    return {
      data: null,
      form: null,
      showNew: false
    }
  },
  components: {
    CampaignList,
    CampaignAdd
  },
  methods: {
    onNew() {
      this.showNew = true
    },
    onBack() {
      this.showNew = false
    },
    onEdit(obj) {
      this.showNew = true
      setTimeout(() => {
        this.$refs.CampaignAdd.onEdit(obj)
      }, 200)
    }
  }
}