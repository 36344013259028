import ActionsForm from '@/shared/mixins/actionsForm'
import { loading } from '@/shared/utils/UIHelper'
import Tools from '@/components/tools/Tools.js'

import EmailTemplateRepository from '@/shared/http/repositories/admin/emailtemplate.js'
import EmailTemplateModel from '@/shared/models/admin/emailtemplate.js'
import TemplateAddForms from './templateadd-form.js'

export default {
  name: 'TemplateAdd',
  mixins: [ActionsForm],
  data () {
    return {
      data: EmailTemplateModel,
      form: TemplateAddForms
    }
  },
  methods:{
    onCreate () {
      loading.push()
      EmailTemplateRepository.Create(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    },
    onUpdate () {
      loading.push()
      EmailTemplateRepository.Update(this.data)
        .then((response) => {
          this.onResult(response)
          this.$emit('Back')
        })
        .catch(this.onException)
    }
  }
}