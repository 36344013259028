<template>
  <div class="animated fadeIn">
    <Alerts ref="alert"/>
    <Forms ref="frmGroup"
           :title="this.$lang.TemplateReasonLoss.TitleForm"
           :showHelp="true"
           :showClearButton="true"
           :showSaveButton="true"
           v-on:Clear="onClear"
           v-on:Save="onSave"
    >
      <div class="form-group row">
        <div class="col-md-12">
          <label for="txtName"><small class="text-danger">*</small> {{$lang.TemplateReasonLoss.LabelName}}:</label>
          <div class="input-group">
            <b-form-input type="text"
                          id="txtName"
                          name="txtName"
                          :placeholder="$lang.TemplateReasonLoss.PlaceholderEnterName"
                          v-model="form.name.value"
                          :size="form.name.maxlength"
                          :required="form.name.required"
                          :state="form.name.state"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-12">
          <label for="txtDescription"> {{$lang.TemplateReasonLoss.LabelDescription}}:</label>
          <b-form-textarea id="txtDescription"
                           name="txtDescription"
                           v-model="form.description.value"
                           :placeholder="$lang.TemplateReasonLoss.PlaceholderEnterDescription"
                           :rows="form.description.rows"
                           :size="form.description.maxlength"
                           :noResize="form.description.noResize"
          ></b-form-textarea>
          <small class="small-help"><i class="fa fa-info-circle"></i> <span v-html="FieldSizeTip"></span>.</small>
        </div>
      </div>
    </Forms>

    <Tables ref="tableGrid"
            :columns="[$lang.TemplateReasonLoss.DataTable.Name,
                       $lang.Common.DataTable.Status,
                       $lang.Common.DataTable.ActionDate,
                       $lang.Common.DataTable.AccountAction,
                       $lang.Common.DataTable.Actions]"
            :showSearch="true"
            :showPrint="false"
            :showDownload="false"
            :showFullscreen="false"
            v-on:Load="onLoad"
    >
      <tr v-for="obj in this.objList" :key="obj.id">
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.name" />
        <TableColumn :IsStatus="true" v-on:Edit="onEdit(obj)" :status="obj.status" />
        <TableColumn :IsDatetime="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.dateAction" />
        <TableColumn :IsText="true" v-on:Edit="onEdit(obj)" :status="obj.status" :value="obj.accountUserNameAction" />
        <TableColumn :IsActionsButtons="true" v-on:Change="onChange(obj)" v-on:Delete="onDelete(obj)" :status="obj.status" />
      </tr>
    </Tables>
  </div>
</template>

<script>
import ReasonLoss from './reasonloss.js'

export default ReasonLoss
</script>
