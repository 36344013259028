export default {
  id: null,
  tenantID: null,
  emailTemplateID: null,
  emailListID: null,
  name: null,
  dateCreated: null,
  dateSend: null,
  situation: 0,
  status: null,
  dateAction: null,
  accountIDUpdate: null
}
