<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-bold text-third mb-2"><i class="fas fa-clipboard-list"/> Detalhes</h5>
        <div class="form-group row">
          <div class="col-md-6 pt-2 pb-2">
            <label>Nome do cliente:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-house-user"></i></span>
              <b-form-input
                type="email"
                v-model="costumer.name"
                readonly
              />
            </div>
          </div>
          <div class="col-md-2 pt-2 pb-2">
            <label>Criado em:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-calendar-check"></i></span>
              <b-form-input
                type="text"
                v-model="costumer.dateUpdateFormated"
                readonly
              />
            </div>
          </div>
          <div class="col-md-4 pt-2 pb-2">
            <label>Chave:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-key"></i></span>
              <b-form-input
                type="text"
                v-model="costumer.id"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 pt-4">
        <h5 class="text-bold text-third mb-2"><i class="fas fa-user-cog"/> Configurações</h5>
      </div>
    </div>
    <div class="pt-2">
      <b-tabs v-model="tabIndex">
        <b-tab title="Contas" active @click="onLoadAllAccount">
          <template slot="title">
            <i class="fa fa-user text-third"></i><span class="d-md-down-none pl-1 text-third"> Contas</span>
          </template>
          <CostumerAccount ref="CostumerAccount" :costumer="costumer" />
        </b-tab>
        <b-tab title="Grupos">
          <template slot="title">
            <i class="fa fa-users text-third"></i><span class="d-md-down-none pl-1 text-third"> Grupos</span>
          </template>
          <CostumerGroup ref="CostumerGroup" :costumer="costumer" />
        </b-tab>
        <b-tab title="Empresa" @click="onLoadAllCompany">
          <template slot="title">
            <i class="fa fa-building text-third"></i><span class="d-md-down-none pl-1 text-third"> Empresa</span>
          </template>
          <CostumerCompany ref="CostumerCompany" :costumer="costumer" />
        </b-tab>
        <b-tab title="Filiais" @click="onLoadAllBranch">
          <template slot="title">
            <i class="fa fa-store text-third"></i><span class="d-md-down-none pl-1 text-third"> Filiais</span>
          </template>
          <CostumerBranch ref="CostumerBranch" :costumer="costumer" />
        </b-tab>
        <b-tab title="Segmento">
          <template slot="title">
            <i class="fa fa-boxes text-third"></i><span class="d-md-down-none pl-1 text-third"> Segmento</span>
          </template>
          <CustomerSegment ref="CustomerSegment" :costumer="costumer" />
        </b-tab>
        <b-tab title="Categoria" @click="onLoadAllCategory">
          <template slot="title">
            <i class="fa fa-gifts text-third"></i><span class="d-md-down-none pl-1 text-third"> Categoria</span>
          </template>
          <CustomerCategory ref="CustomerCategory" :costumer="costumer" />
        </b-tab>
        <b-tab title="Produtos">
          <template slot="title">
            <i class="fa fa-gift text-third"></i><span class="d-md-down-none pl-1 text-third"> Produtos</span>
          </template>
          <CustomerProduct ref="CustomerProduct" :costumer="costumer" />
        </b-tab>
        <b-tab title="Origem">
          <template slot="title">
            <i class="fas fa-user-plus text-third"></i><span class="d-md-down-none pl-1 text-third"> Origem</span>
          </template>
          <CustomerOrigin ref="CustomerOrigin" :costumer="costumer" />
        </b-tab>
        <b-tab title="Forma Contato">
          <template slot="title">
            <i class="fas fa-headset text-third"></i><span class="d-md-down-none pl-1 text-third"> Forma Contato</span>
          </template>
          <CustomerContact ref="CustomerContact" :costumer="costumer" />
        </b-tab>
        <b-tab title="Motivos Perda">
          <template slot="title">
            <i class="fas fa-user-times text-third"></i><span class="d-md-down-none pl-1 text-third"> Motivos Perda</span>
          </template>
          <CustomerReason ref="CustomerReason" :costumer="costumer" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Tools from '@/components/tools/Tools.js'
import { toast, loading } from '@/shared/utils/UIHelper'
import ActionsForm from '@/shared/mixins/actionsForm'

import CostumerRepository from '@/shared/http/repositories/access/costumer'
import SupportAuthRepository from '@/shared/http/repositories/admin/supportauth'

import CostumerCompany from './company/company.vue'
import CostumerBranch from './branch/branch.vue'
import CostumerGroup from './group/group.vue'
import CostumerAccount from './account/accounts.vue'
import CustomerSegment from './segment/segment.vue'
import CustomerCategory from './category/category.vue'
import CustomerProduct from './product/product.vue'
import CustomerOrigin from './origin/origin.vue'
import CustomerContact from './contact/contact.vue'
import CustomerReason from './reason/reason.vue'

export default {
  name: 'CostumerAccounts',
  mixins: [ActionsForm],
  props: {
    costumer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  components: {
    CostumerAccount,
    CostumerGroup,
    CostumerCompany,
    CostumerBranch,
    CustomerSegment,
    CustomerCategory,
    CustomerProduct,
    CustomerOrigin,
    CustomerContact,
    CustomerReason
  },
  watch: {
    'costumer.id': function () {
      this.onLoad()
    }
  },
  methods: {
    onLoad() {
    },
    onLoadAllAccount () {
      this.$refs.CostumerAccount.onLoadAllCostumerAccounts(this.costumer.id)
    },
    onLoadAllCompany () { 
      this.$refs.CostumerCompany.onLoadAllCompany(this.costumer.id)
    },
    onLoadAllBranch () {
      this.$refs.CostumerBranch.onLoadAllBranch(this.costumer.id)
    },
    onLoadAllCategory () {
      this.$refs.CustomerCategory.onLoadAllCategory(this.costumer.id)
    }
  }
}
</script>

<style>
.text-icon {
  font-size: 18px;
}
</style>