export default {
  name: {
    value: '',
    maxlength: '70',
    required: true,
    state: null
  },
  emailTemplateID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  emailListID: {
    value: null,
    options: [],
    required: true,
    state: null
  },
  dateCreated: {
    value: '',
    maxlength: '10',
    required: false,
    state: null
  },
  dateSend: {
    value: '',
    maxlength: '10',
    required: false,
    state: null
  },
  situation: {
    value: 0,
    maxlength: '1',
    required: false,
    state: null
  },
}
